export async function callHook({uid, url, params={}}) {
  let response = null;
  try {
    const options = {
      method: 'POST',
      mode: "cors", // no-cors, *cors, same-origin
      redirect: 'follow',
      headers: { "content-type": "application/json" },
    };
    //console.log(`send to: ${url}`);

    // const data = JSON.stringify(params);

    options.body = JSON.stringify({uid, data:params});
    response = await fetch(url, options);
    // console.log(response);
    //console.log('status code: ', response.status); // 👉️ 200

    if (!response.ok) {
      //console.log(response);
      throw new Error(`Error! status: ${response.status}`);
    }
    const json = await response.json();
    return json;
  } catch (e) {
    // interface Error {
    //   name: string;
    //   message: string;
    //   stack?: string;
    // }
    const status = response ? response.status : null;
    //console.log(e);
    const message = e.message || 'Error';
    const r = {success: false, message, status}
    return r;
  }
}