import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';

export const ContactForm = ({ slice }) => {

  const {title, title_2, text, phone, email, address} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/website-forms";

    setLoading(true)
    const r = await callHook({url, uid:"online-vinotheken-contact-form", params:data});
    if (r && r.success === true) {
      setError(false);
    } else {
      setError(true);
    }
    setLoading(false);
    setSend(true);
  }

  const SimpleContactForm = ({ }) => {

    if (isLoading && !isSend) {
      return (
        <div className="text-start">
          <div className="loader mx-auto"></div>
        </div>
      );
    }

    if (isSend) {
      return (
        <div className="text-start">
          {!isError && (
          <>
            <h3>Danke!</h3>
            <p>Wir werden Sie in Kürze kontaktieren.</p>
          </>
          )}
          {isError && (
          <>
            <h3>Es ist ein Fehler aufgetreten</h3>
            <p>Bitte versuchen Sie es später erneut.</p>
          </>
          )}
          <div className="col-auto">
            <button onClick={()=>setSend(false)} className={"btn text-uppercase btn-primary"}>{isError ? "Erneut versuchen" : "Wiederholen"}</button>
          </div>*
        </div>
      );
    }

    return (
      <div className="">
        <h3 className="b-contacts__feedback-title">{title}</h3>
        <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              placeholder="Ihr Name"
              className={'form-control'}
              {...register('name', {required: false})}
            />
          </div>
          <div className={"mt-4"}>
            <input
              placeholder="Ihre E-Mail-Adresse"
              className={"form-control"}
              required
              {...register('email', {required: true})}
            />
          </div>
          <div className={"mt-4"}>
            <textarea
              placeholder="Geben Sie hier Ihre Nachricht ein"
              type="text"
              rows="5"
              className={"form-control"}
              required
              {...register('text', {required: true})}
            />
          </div>
          <input type="submit" value="Nachricht senden" className={"mt-4 btn btn-primary"}/>
        </form>
		  </div>
    )
  }

  return (
    <div className="b-contacts" id="contacts">
      <div className="container">
        <div className="b-contacts__box">
          <div className="b-contacts__row">
            <div className="b-contacts__col-left">
              <div className="b-contacts__feedback">
                <SimpleContactForm/>
              </div>
            </div>
            <div className="b-contacts__col-right">
              <div className="b-contacts__ico"></div>
              <h3 className="b-contacts__title">{title_2}</h3>
              <PrismicRichText field={text.richText} />
              <ul className="b-contacts__list">
                <li><span className="ico-phone"></span><a className="no-decor" href="tel:+4915153578701">{phone}</a></li>
                <li><span className="ico-mail"></span><a className="no-decor" href="mailto:info@online-vinotheken.de">{email}</a></li>
                <li><span className="ico-location"></span><span>{address}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContactForm on PrismicHomepageDataBodyContactForm {
    primary {
      title
      title_2
      text {
        richText
      }
      phone
      email
      address
    }
  }
`
